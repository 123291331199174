<template>
    <div>
      <nav aria-label="Page navigation example" v-if="data.lastPage > 1">
          <ul class="pagination">
            <li class="page-item" v-if="data.page > 1"><a class="page-link" href="javascript:;" @click="search(1)">First</a></li>
            <li class="page-item" v-if="data.page - 4 >= 1"><a class="page-link" href="javascript:;" @click="search(data.page - 4)">{{data.page - 4}}</a></li>
            <li class="page-item" v-if="data.page - 3 >= 1"><a class="page-link" href="javascript:;" @click="search(data.page - 3)">{{data.page - 3}}</a></li>
            <li class="page-item" v-if="data.page - 2 >= 1"><a class="page-link" href="javascript:;" @click="search(data.page - 2)">{{data.page - 2}}</a></li>
            <li class="page-item" v-if="data.page - 1 >= 1"><a class="page-link" href="javascript:;" @click="search(data.page - 1)">{{data.page - 1}}</a></li>
            <li class="page-item active"><a class="page-link" href="javascript:;">{{data.page}}</a></li>
            <li class="page-item" v-if="data.page + 1 <= data.lastPage"><a class="page-link" href="javascript:;" @click="search(data.page+ 1)">{{data.page + 1}}</a></li>
            <li class="page-item" v-if="data.page + 2 <= data.lastPage"><a class="page-link" href="javascript:;" @click="search(data.page + 2)">{{data.page + 2}}</a></li>
            <li class="page-item" v-if="data.page + 3 <= data.lastPage"><a class="page-link" href="javascript:;" @click="search(data.page + 3)">{{data.page + 3}}</a></li>
            <li class="page-item" v-if="data.page + 4 <= data.lastPage"><a class="page-link" href="javascript:;" @click="search(data.page + 4)">{{data.page + 4}}</a></li>
            <li class="page-item" v-if="data.page < data.lastPage"><a class="page-link" href="javascript:;" @click="search(data.lastPage)">Last</a></li>
          </ul>
        </nav>
        <p class="float-right text-white" v-if="data.total > 0">Showing {{start}} - {{end}} of {{data.total.toLocaleString()}}</p>
    </div>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
          data: Object,
        },
        data(){
            return {

            }
        },
        methods: {
            search(page){
                this.$emit('search', page);
            }
        },
      computed: {
          start: function(){
            return ((this.data.page -1) * this.data.showing).toLocaleString()
          },
          end: function(){
            let end = ((this.data.page) * this.data.showing);
            if(end > this.data.total){
              return this.data.total.toLocaleString();
            }
            return end.toLocaleString();
          }
      }
    }
</script>

<style scoped>

</style>
