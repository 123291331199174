<template>
  <div>

    <div class="card">
      <div class="card-body">

        <div class="position-relative wd-50">
          <input v-on:keyup="loadClients(1)" type="text" class="form-control ps-5" v-model="filters.search" placeholder="Search Company..."> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
        </div>

        <br/>
        <pagination @search="loadClients" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
            <tr>
              <th><!-- LOGO --></th>
              <th>Name</th>
              <th>Street</th>
              <th>Town</th>
              <th>County</th>
              <th>Postcode</th>
              <th># Sites</th>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="client in clients" :key="client.id">
              <td><secure-image v-if="client.logo" style="height: 1rem;" :id="client.name + '-logo'" :endpoint="`/v1/client/${client.id}/logo`"></secure-image></td>
              <td>{{client.name}}</td>
              <td>{{client.street}}</td>
              <td>{{client.town}}</td>
              <td>{{client.county}}</td>
              <td>{{client.postcode}}</td>
              <td>#{{client.siteCount}}</td>
              <td>
                <button type="button" style="float: right" @click="downloadOpenJobsReport(client)"  class="btn btn-sm btn-info px-5">Open Jobs Report</button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th><!-- LOGO --></th>
              <th>Name</th>
              <th>Street</th>
              <th>Town</th>
              <th>County</th>
              <th>Postcode</th>
              <th># Sites</th>
              <td></td>
            </tr>
          </tfoot>
        </table>

        <pagination @search="loadClients" :data="pagination"></pagination>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Pagination from '../../../components/Pagination.vue';
import SecureImage from '../../../components/SecureImage.vue';
import { saveAs } from 'file-saver';
export default {
  components: { Pagination, SecureImage },
  name: 'admin.clients',
  data(){
    return {
      clients: [],
      filters: {
        search: ""
      },
      pagination: {
        lastPage: 0,
        page: 0,
        showing: 0,
        total: 0
      }
    }
  },
  mounted(){
    this.loadClients()
  },
  methods: {
    loadClients(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/my/client?page=${page}&search=${this.filters.search}`)
      .then(response => {
        this.clients = response.data.clients;
        this.pagination = response.data.pagination;
      })
      .catch(error => {
        this.$error('Failed to load clients.', error);
      });
    },
    downloadOpenJobsReport(client){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/my/reports/client?id=${client.id}`, {
        responseType: "blob"
      })
          .then(response => {
            saveAs(response.data, 'open-jobs-report.pdf');
          })
          .catch(error => {
            this.$error("Failed to generate report", error);
          })
    }

  }
}
</script>