<template>
    <img :id="id" v-if="src !== null" :src="src" @click="$emit('click')" @load="$emit('load')" v-viewer>
</template>

<script>
import axios from 'axios';
export default {
    name: "SecureImage",
    props: {
        id: String,
        endpoint: {
            type: String,
            required: false,
        }
    },
    data(){
        return {
            src: null,
        }
    },
    mounted() {

        let API_ENDPOINT = '/v1/';
        if(this.endpoint !== undefined){
            if(this.endpoint.length > 0){
                API_ENDPOINT = this.endpoint;
            }
        }

        return axios.get(`${process.env.VUE_APP_API_URL}${API_ENDPOINT}`, {
            responseType: 'arraybuffer'
        })
            .then(response => {
                this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
            })
            .catch(error => {
                this.$error(`Failed to download image ${this.filename}`, error);
                return null;
            });

    },
    methods: {
          loadImage(){
              let API_ENDPOINT = '/v1/';
              if(this.endpoint !== undefined){
                  if(this.endpoint.length > 0){
                      API_ENDPOINT = this.endpoint;
                  }
              }

              return axios.get(`${process.env.MIX_API_URL}${API_ENDPOINT}`, {
                  responseType: 'arraybuffer'
              })
                  .then(response => {
                      this.src = `data:image/jpeg;base64,${Buffer.from(response.data, 'binary').toString('base64')}`;
                  })
                  .catch(error => {
                      this.$error(`Failed to download image ${this.filename}`, error);
                      return null;
                  });
          }
    },
    watch: {
        filename: function(){
            this.loadImage();
        },
        site_id: function(){
            this.loadImage();
        },
        endpoint: function(){
            this.loadImage();
        }
    }
}
</script>

<style scoped>

</style>
